import { Injectable } from '@angular/core';
import { RedeemablePackageDefinition, RedeemablePackageOffer } from '../models/package-definition';
import { Package } from '../models/package';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { DataCacherService } from './data-cacher-service';
import { copyToArray } from '../functions/misc';

@Injectable({
  providedIn: 'root'
})
export class PackagesService implements DataCacherService {
  private readonly listPackageDefinitionsApiUrlFragment: string = "/api/vendor/packages/purchaseable_package_types";
  private readonly listRedeemablePackagesApiUrlFragment: string = "/api/vendor/packages";
  private readonly handOutPackageApiUrlFragment: string = "/api/vendor/packages/send_package_to_email";

  private packageDefinitions: Array<RedeemablePackageDefinition>;
  private redeemablePackages: Array<Package>;

  constructor(
    private backendService: BackendService,
    private httpErrorHandlerService: HttpErrorHandlerService
  ) {
    this.packageDefinitions = new Array<RedeemablePackageDefinition>();
    this.redeemablePackages = new Array<Package>();
  }

  public clearCachedData(): void {
    this.packageDefinitions.length = 0;
    this.redeemablePackages.length = 0;
  }

  public getPackageDefinitonsArrayRef(): ReadonlyArray<RedeemablePackageDefinition> {
    return this.packageDefinitions;
  }

  public async getPackageDefinitions(): Promise<ReadonlyArray<RedeemablePackageDefinition>> {
    try {
      const response: { packageDefinitions: Array<RedeemablePackageDefinition> } = await this.backendService.callApi(this.listPackageDefinitionsApiUrlFragment, "GET");
      this.packageDefinitions.length = 0;
      copyToArray(this.packageDefinitions, response.packageDefinitions);

      this.packageDefinitions.forEach(
        (packageDefinition: RedeemablePackageDefinition) => {
          packageDefinition.offers.forEach(
            (packageDefinitionOffer: RedeemablePackageOffer) => {
              packageDefinitionOffer.netPrice /= 100;
            }
          )
        }
      )

    } catch (error: any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a beváltható csomagok lekérdezése közben.");
    }

    return this.packageDefinitions;
  }

  public getRedeemablePackagesArrayRef(): ReadonlyArray<Package> {
    return this.redeemablePackages;
  }

  public async getRedeemablePackages(): Promise<ReadonlyArray<Package>> {
    try {
      const response: { packages: Array<Package>; } = await this.backendService.callApi(this.listRedeemablePackagesApiUrlFragment, "GET");
      this.redeemablePackages.length = 0;
      console.log(response);
      copyToArray(this.redeemablePackages, response.packages);
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a beváltható csomagok lekérdezése közben.");
    }

    return this.redeemablePackages;
  }


  public async handOutPackageToStudent(studentEmail: string, packageDefinition: RedeemablePackageDefinition, packageUuid?: string): Promise<PackageHandingOutResult> {
    let redeemablePackage: Package | undefined;
    let packageHandingOutResult: PackageHandingOutResult;

    if (packageUuid) {
      redeemablePackage = this.redeemablePackages.find(
        redeemablePackage => {
          return redeemablePackage.uuid === packageUuid;
        }
      );
    } else {
      redeemablePackage = this.redeemablePackages.find(
        redeemablePackage => {
          return redeemablePackage.packageDefinitionUuid === packageDefinition.uuid && redeemablePackage.redemptionInformation?.redemptionLinkSentTo == null;
        }
      );
    }

    try {
      if (!redeemablePackage) {
        throw { error: { error: "No handable package." } };
      }

      const httpBody: Object = {
        data: {
          packageUuid: redeemablePackage.uuid,
          studentEmail: studentEmail
        }
      }


      const response: PackageHandingOutResult = await this.backendService.callApi(this.handOutPackageApiUrlFragment, "POST", httpBody);
      packageHandingOutResult = response;

      await this.getPackageDefinitions();
      
    } catch (error: any) {
      console.error(error);
      this.httpErrorHandlerService.handleError(error, "Hiba a csomag kiosztása közben.");
    }

    return packageHandingOutResult!;
  }


}

export type PackageHandingOutResult = {
  activationCode: string;
  studentEmail: string;
  timestamp: number;
}