import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleGuardService } from 'src/app/services/common/guards/role-guard.service';
import { SelfPermissionsService } from 'src/app/services/self-permissions.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  isRequestLoading:boolean = false;

  constructor(
    public elementRef:ElementRef<HTMLElement>,
    private selfPermissionsService:SelfPermissionsService,
    private roleGuardService:RoleGuardService,
    private activatedRoute:ActivatedRoute,
    private routerService:Router
  ) { }

  public async ngOnInit():Promise<void> {
    this.isRequestLoading = true;
    await this.loadPermissions();
    await this.checkActualRoute();
    this.isRequestLoading = false;
  }

  private async loadPermissions():Promise<void> {
    try {
      await this.selfPermissionsService.updateSelfPermissions();
    } catch(error:any) {}
  }

  private async checkActualRoute():Promise<void> {
    if(!this.roleGuardService.canActivate(this.activatedRoute.snapshot, this.routerService.routerState.snapshot)) {
      await this.routerService.navigate([ "/" ], { queryParams: {} });
    }
  }
}
