<div class = "menu-items">
  @if (isMenuOpen) {
    <div class = "mobil-user-info">
      <div class = "user-info">
        <div class = "logged-in-as-name">
          {{ displayedUsername }}
        </div>
        <div class = "logged-in-as-type">
          {{ displayedUserType }}
        </div>
      </div>
    </div>
  }

  @for (menuItem of menuItems; track menuItem; let i = $index) {
    <div
      class = "menu-item"
      [ngClass] = "selectedMenuIndex === i ? 'selected' : ''"
      >
      <a [routerLink] = "menuItem.route">
        {{ menuItem.displayedName }}
      </a>
    </div>
  }
  <div class = "menu-item logout" (click) = "logout()">
    Kijelentkezés
  </div>
</div>

<div class = "deskop-user-info">
  <div class = "user-info">
    <div class = "logged-in-as-name">
      {{ displayedUsername }}
    </div>
    <div class = "logged-in-as-type">
      {{ displayedUserType }}
    </div>
  </div>
  <div class = "logout" (click) = "logout()">
    Kijelentkezés
  </div>
</div>

<!-- Mobile -->
<div class = "actual-menu">
  {{ menuItems[selectedMenuIndex].displayedName }}
</div>
<div class = "hamburger-container">
  <button class = "hamburger hamburger--squeeze" [ngClass] = "isMenuOpen ? 'is-active' : ''" type = "button" (click) = "toogleMenu()">
    <span class = "hamburger-box">
      <span class = "hamburger-inner"></span>
    </span>
  </button>
</div>