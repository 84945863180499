import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Package, PackageRedeemer } from 'src/app/models/package';
import { RedeemablePackageDefinition } from 'src/app/models/package-definition';
import { PackagesService } from 'src/app/services/packages.service';
import { StudentInfoDialogComponent } from '../common/student-info-dialog/student-info-dialog.component';
import { HandOutPackageDialogComponent } from '../my-packages/hand-out-package-dialog/hand-out-package-dialog.component';

@Component({
  selector: 'app-handed-out-packages',
  templateUrl: './handed-out-packages.component.html',
  styleUrls: ['./handed-out-packages.component.scss']
})
export class HandedOutPackagesComponent implements OnInit {
  isContentLoading:boolean = false;

  handedOutPackages:Array<HandedOutPackage> = [];

  constructor(
    private packagesService:PackagesService,
    private snackBarService:MatSnackBar,
    private dialogService:MatDialog
  ) { }

  public async ngOnInit():Promise<void> {
    this.isContentLoading = true;

    try {      
      const redeemablePackages:ReadonlyArray<Package> = await this.packagesService.getRedeemablePackages();
      await this.packagesService.getPackageDefinitions();

      this.handedOutPackages = redeemablePackages.filter(
        (redeemablePackage:Package) => {
          return redeemablePackage.redemptionInformation?.redemptionLinkSentTo;
        }
      ).map(
        (redeemablePackage:Package) => {
          return {
            uuid: redeemablePackage.uuid,
            packageDefinitionUuid: redeemablePackage.packageDefinitionUuid,
            displayedPackageName: redeemablePackage.displayedName,
            handOutTimestamp: redeemablePackage.redemptionInformation!.redemptionLinkSentTimestamp!,
            studentEmail: redeemablePackage.redemptionInformation!.redemptionLinkSentTo!,
            isPending: redeemablePackage.redemptionInformation!.redeemer == undefined,
            redeemer: redeemablePackage.redemptionInformation?.redeemer ?? null,
            redemptionTimestamp: redeemablePackage.redemptionInformation!.redemptionTimestamp!
          }
        }
      );

      this.handedOutPackages.sort(handedOutPackageSorter);
    } catch(error:any) {}

    this.isContentLoading = false;
  }

  public handleModifyHandoutButtonClick(handedOutPackage:HandedOutPackage):void {
    const packageDefinition:RedeemablePackageDefinition|undefined = this.packagesService.getPackageDefinitonsArrayRef().find(
      redeemablePackageDefinition => redeemablePackageDefinition.uuid === handedOutPackage.packageDefinitionUuid
    );

    if(!packageDefinition) {
      this.snackBarService.open("Ismeretlen csomagtípus!");
      return;
    }

    const dialogRef:MatDialogRef<HandOutPackageDialogComponent, boolean> = this.dialogService.open(
      HandOutPackageDialogComponent,
      {
        data: {
          packageDefinition: packageDefinition,
          defaultStudentEmail: handedOutPackage.studentEmail,
          packageUuid: handedOutPackage.uuid,
          handedOutPackage: handedOutPackage
        }
      }
    );

    const dialogCloseSubscription:Subscription = dialogRef.afterClosed().subscribe(
      (result?:boolean) => {
        dialogCloseSubscription.unsubscribe();
        if(result) {
          this.handedOutPackages.sort(handedOutPackageSorter);
        }
      }
    );

  }

  public openStudentInfoDialog(handedOutPackage:HandedOutPackage):void {
    if(handedOutPackage.redeemer === null) {
      return;
    }

    this.dialogService.open(
      StudentInfoDialogComponent,
      {
        data: {
          studentUuid: handedOutPackage.redeemer.redeemerStudentUuid
        },
        disableClose: true,
        hasBackdrop: true,
        backdropClass: 'invisible-backdrop',
        scrollStrategy: new NoopScrollStrategy()
      }
    );
  }

}

export type HandedOutPackage = {
  uuid:string;
  packageDefinitionUuid:string;
  handOutTimestamp:number;
  displayedPackageName:string;
  studentEmail:string;
  isPending:boolean;
  redemptionTimestamp?:number;
  redeemer:PackageRedeemer|null
}

export function handedOutPackageSorter(package1:HandedOutPackage, package2:HandedOutPackage):number {
  if(package1.isPending !== package2.isPending) {
    if(package1.isPending) {
      return -1;
    } else {
      return 1;
    }
  } else {
    return package2.handOutTimestamp - package1.handOutTimestamp;
  }
}