import { Injectable } from '@angular/core';
import { DataCacherService } from './data-cacher-service';
import { PaymentRequest, paymentRequestSorter } from '../models/payment-request';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { RedeemablePackageDefinition } from '../models/package-definition';
import { PackagesService } from './packages.service';
import { copyToArray } from '../functions/misc';

@Injectable({
  providedIn: 'root'
})
export class PaymentRequestService implements DataCacherService {

  private readonly listPaymentRequestsApiUrlFragment:string = "/api/vendor/payments/list_payment_requests";
  private readonly createPaymentRequestApiUrlFragment:string = "/api/vendor/payments/send_payment_request";

  paymentRequests:Array<PaymentRequest>;

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService,
    private packagesService:PackagesService
  ) {
    this.paymentRequests = new Array<PaymentRequest>();
  }

  public getPaymentRequestArrayRef():ReadonlyArray<PaymentRequest> {
    return this.paymentRequests;
  }

  public async getPaymentRequests():Promise<ReadonlyArray<PaymentRequest>> {
    try {
      const response:{ data:Array<PaymentRequest> } = await this.backendService.callApi(this.listPaymentRequestsApiUrlFragment, "GET");
      const packageDefinitions:ReadonlyArray<RedeemablePackageDefinition> = await this.packagesService.getPackageDefinitions();
     
      response.data.forEach(
        (paymentRequest:PaymentRequest) => {
          this.setPaymentRequestValues(paymentRequest, packageDefinitions);
        }
      );

      this.paymentRequests.length = 0;
      copyToArray(this.paymentRequests, response.data);
      this.paymentRequests.sort(paymentRequestSorter);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a fizetési kérelmek lekérdezése közben.");
    }

    return this.paymentRequests;
  }

  public async createPaymentRequest(studentEmail:string, packageDefinition:RedeemablePackageDefinition, grossAmount:number):Promise<void> {
    const httpBody:Object = {
      data: {
        packageDefinitionUuid: packageDefinition.uuid,
        studentEmailAddress: studentEmail,
        grossAmount: grossAmount * 100
      }
    };

    try {
      const response:{ paymentRequest:PaymentRequest } = await this.backendService.callApi(this.createPaymentRequestApiUrlFragment, "POST", httpBody);
      const packageDefinitions:ReadonlyArray<RedeemablePackageDefinition> = await this.packagesService.getPackageDefinitions();
      this.setPaymentRequestValues(response.paymentRequest, packageDefinitions);
      this.paymentRequests.unshift(response.paymentRequest);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a fizetési kérelem létrehozása közben.");
    }
  }

  private setPaymentRequestValues(paymentRequest:PaymentRequest, packageDefinitions:ReadonlyArray<RedeemablePackageDefinition>):void {
    const packageDefinition:RedeemablePackageDefinition|undefined = packageDefinitions.find(packageDefinition => packageDefinition.uuid === paymentRequest.packageDefinitionUuid);
    paymentRequest.netAmount /= 100;
    paymentRequest.grossAmount /= 100;
    paymentRequest.netAmountBasePrice /= 100;
    paymentRequest.packageDisplayedName = packageDefinition?.displayedName ?? "Ismeretlen csomag";
    paymentRequest.paymentTimestamp ??= null;
  }

  public clearCachedData():void {
    this.paymentRequests.length = 0;
  }

}

