<div class = "title">
  {{ data.packageDefinitionDisplayedName }}
</div>
<div class="content">
  @for (descriptionLine of data.descriptionLines; track descriptionLine) {
    <div
      class = "description-line-item"
      >
      <div class="description-text">
        {{ descriptionLine.text }}
      </div>
      @if (descriptionLine.explanation) {
        <div
          class = "description-explanation"
          >
          {{ descriptionLine.explanation }}
        </div>
      }
    </div>
  }
</div>

<div class = "close-button-container">
  <div class = "app-button close-button" mat-dialog-close>
    Rendben
  </div>
</div>
