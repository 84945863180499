// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

console.info("Actual environment: development");

export const environment = {
  production: false,
  serverAddress: "https://development.mrkresz.hu"
};
