import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-purchase-result-dialog',
  templateUrl: './purchase-result-dialog.component.html',
  styleUrls: ['./purchase-result-dialog.component.scss']
})
export class PurchaseResultDialogComponent implements OnInit {

  text!:string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{ purchaseResult:"SUCCESS"|"FAILED" },
    private dialogRef:MatDialogRef<PurchaseResultDialogComponent>
  ) { }

  ngOnInit(): void {
    switch(this.data.purchaseResult) {
      case "SUCCESS":
        this.text = "Sikeres vásárlás";
        break;
      case "FAILED":
        this.text = "Sikertelen vásárlás";
        break;
      default:
       this.dialogRef.close();
    }
  }

}
