import { Pipe, PipeTransform } from '@angular/core';
import { netToGrossValue } from '../functions/misc';

@Pipe({
  name: 'netToGrossValue'
})
export class NetToGrossValuePipe implements PipeTransform {

  transform(value:number, customTaxValue?:number):number {
    return netToGrossValue(value, customTaxValue);
  }

}
