export type PaymentRequest = {
    uuid:string;
    customerEmail:string;
    netAmount:number;
    grossAmount:number;
    netAmountBasePrice:number;
    isPaid:boolean;
    creationTimestamp:number;
    packageDefinitionUuid:string;
    paymentTimestamp:number|null;
    stripeProductId:string;
    packageDisplayedName?:string; // Calculated field
}

export function paymentRequestSorter(paymentRequest1:PaymentRequest, paymentRequest2:PaymentRequest):number {
    return paymentRequest2.creationTimestamp - paymentRequest1.creationTimestamp;
}