<div class = "section-header">
  <div class="left">
    <div class = "app-title">
      Csomag vásárlás
    </div>

    <!-- Átmenetileg kivéve, funkcionaliásan nincs vele probléma, a design-ba kell neki egy jobb hely -->
    <!--  <button
    class = "app-button sync"
    (click) = "syncPayments()"
    [class.disabled]="isContentLoading"
    >
    Szinkronizálás
  </button> -->
</div>
<button
  class="get-gift-with-colleague-button app-button"
  (click)="onGetGiftWithColleagueButtonClick()"
  >
  <span>
    Zsebelj be a kollégáddal <span style="white-space: nowrap;">25-25</span> ezer forintot!
  </span>
  <img class="get-gift-with-colleague-button-icon" src="assets/images/svg/gift-package.svg" />
</button>
</div>

@if (isContentLoading) {
  <div class="app-loading-container">
    <mat-spinner></mat-spinner>
  </div>
} @else {
  <!-- Theory module packages -->
  <div class="package-type-section">
    <div class="packages-title"> Elméleti gyakorló csomagok </div>
    <div class="packages-definitions-list">
      @for (packageDefinition of theoryPackageDefinitions; track packageDefinition) {
        <app-package-definition-card
          [packageDefinition] = "packageDefinition"
          class="package-definition-card"
          (handleOutButtonClick)="loadPackageDefinitions()"
        ></app-package-definition-card>
      }
      @if (theoryPackageDefinitions.length === 0) {
        <div class = "no-available-package-type">
          Nincs elérhető csomagtípus.
        </div>
      }
    </div>
  </div>
  <!-- Practical module packages -->
  <div class="package-type-section">
    <div class="packages-title"> Forgalmi felkészítő csomagok </div>
    <div class="packages-definitions-list">
      @for (packageDefinition of practicePackageDefinitions; track packageDefinition) {
        <app-package-definition-card
          [packageDefinition] = "packageDefinition"
          class="package-definition-card"
          (handleOutButtonClick)="loadPackageDefinitions()"
        ></app-package-definition-card>
      }
      @if (practicePackageDefinitions.length === 0) {
        <div class = "no-available-package-type">
          Nincs elérhető csomagtípus.
        </div>
      }
    </div>
  </div>
  <!-- Misc packages packages -->
  @if (miscPackageDefinitions.length > 0) {
    <div class="package-type-section">
      <div class="packages-title"> Egyéb csomagok </div>
      <div class="packages-definitions-list">
        @for (packageDefinition of miscPackageDefinitions; track packageDefinition) {
          <app-package-definition-card
            [packageDefinition] = "packageDefinition"
            class="package-definition-card"
            (handleOutButtonClick)="loadPackageDefinitions()"
          ></app-package-definition-card>
        }
      </div>
    </div>
  }
  <!-- Inactice packages -->
  @if (inactivePackageDefinitions.length > 0) {
    <div class="package-type-section">
      <div class="packages-title"> Inaktív csomagok </div>
      <div class = "inactive-packages-description">
        Az itt található csomagok már nem vásárolhatóak, de még kioszthatod őket.
      </div>
      <div class="packages-definitions-list">
        @for (packageDefinition of inactivePackageDefinitions; track packageDefinition) {
          <app-package-definition-card
            [packageDefinition] = "packageDefinition"
            [isPurchaseAvailable]="false"
            class="package-definition-card"
            (handleOutButtonClick)="loadPackageDefinitions()"
          ></app-package-definition-card>
        }
      </div>
    </div>
  }
}


