import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RedeemablePackageDefinition } from 'src/app/models/package-definition';
import { PackageHandingOutResult, PackagesService } from 'src/app/services/packages.service';
import { HandedOutPackage } from '../../handed-out-packages/handed-out-packages.component';

@Component({
  selector: 'app-hand-out-package-dialog',
  templateUrl: './hand-out-package-dialog.component.html',
  styleUrls: ['./hand-out-package-dialog.component.scss']
})
export class HandOutPackageDialogComponent implements OnInit {

  studentEmailFC: FormControl<string | null>;

  isRequestLoading:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{ packageDefinition:RedeemablePackageDefinition, defaultStudentEmail?:string, packageUuid?:string, handedOutPackage?:HandedOutPackage },
    private dialogRef:MatDialogRef<HandOutPackageDialogComponent, boolean>,
    private packageService:PackagesService,
    private snackBarService:MatSnackBar
  ) {
    this.studentEmailFC = new FormControl<string | null>(this.data.defaultStudentEmail ?? "", [ Validators.required, Validators.email ]);
  }

  ngOnInit(): void {
  }

  public async handleHandOutButtonClick():Promise<void> {
    if(this.studentEmailFC.invalid || this.isRequestLoading) {
      return;
    }

    this.isRequestLoading = true;

    try {
      const packageHandingOutResult:PackageHandingOutResult = await this.packageService.handOutPackageToStudent(
        this.studentEmailFC.value!, this.data.packageDefinition, this.data.packageUuid
      );
      if(this.data.handedOutPackage) {
        this.data.handedOutPackage.studentEmail = packageHandingOutResult.studentEmail;
        this.data.handedOutPackage.handOutTimestamp = packageHandingOutResult.timestamp;
      }

      this.snackBarService.open("Csomag sikeresen kiosztva.", "Bezár", { duration: 3000 });
      this.dialogRef.close(true);
    } catch(error:any) {}

    this.isRequestLoading = false;
  }

}
