import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TidioChatService implements OnDestroy{
  private isTidioChatVisible: boolean = true;

  constructor() {
    document.addEventListener("tidioChat-ready", this.onLoadedTidioChat);
  }

  ngOnDestroy(): void {
    document.removeEventListener("tidioChat-ready",this.onLoadedTidioChat);
  }

  readonly onLoadedTidioChat = () => {
    if(this.isTidioChatVisible == false){
      this.hideChat();
    }
  }

  public showChat() {
    (window as any).tidioChatApi?.show();
    this.isTidioChatVisible = true;
  }

  public hideChat() {
    (window as any).tidioChatApi?.hide();
    this.isTidioChatVisible = false;
  }
}
