import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService, LoginResult } from 'src/app/services/common/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginFG!: FormGroup;
  emailFC!: FormControl<string | null>;
  passwordFC!: FormControl<string | null>;

  showPassword:boolean = false;

  constructor(
    private authenticationService:AuthenticationService,
    private snackBar:MatSnackBar
  ) { }

  ngOnInit(): void {
    this.emailFC = new FormControl<string | null>("");
    this.passwordFC = new FormControl<string | null>("");

    this.loginFG = new FormGroup({
      emailFC: this.emailFC,
      passwordFC: this.passwordFC
    });
  }

  public async login():Promise<void> {
    if(this.loginFG.invalid) {
      return;
    }

    const loginEmail:string = (this.emailFC.value ?? "").trim();

    const loginResult:LoginResult = await this.authenticationService.login(loginEmail, this.passwordFC.value ?? "");
    switch(loginResult) {
      case LoginResult.WrongCredentials:
        this.snackBar.open("Hibás felhasználónév vagy jelszó.", "Bezár", { duration: 3000 });
        break;
      case LoginResult.ServerError:
        this.snackBar.open("Hiba történt bejelentkezés közben.", "Bezár", { duration: 3000 });
        break;
      case LoginResult.ServerConnectionError:
        this.snackBar.open("Hiba a szerverkapcsolatban.", "Bezár", { duration: 3000 });
        break;
    }
  }

}
