import { Component, Input, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-lottie-animation',
  templateUrl: './lottie-animation.component.html',
  styleUrls: ['./lottie-animation.component.scss']
})
export class LottieAnimationComponent implements OnInit {
  @Input() animationPath!:string;
  @Input() scale:number = 1.0;
  @Input() loop:boolean = false;
  @Input() autoPlay:boolean = true;

  animationOptions!:AnimationOptions;
  animationStyles!:Partial<CSSStyleDeclaration>;

  constructor() { }

  ngOnInit(): void {
    this.animationOptions = {
      autoplay: this.autoPlay,
      loop: this.loop,
      path: this.animationPath
    }

    this.animationStyles = {
      width: 100 * this.scale + "%",
      height: 100 * this.scale + "%",
      position: "absolute",
      left: -((100 * this.scale) - 100) / 2 + "%",
      top: -((100 * this.scale) - 100) / 2 + "%"
    }

  }

}
