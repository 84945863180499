<div class = "header" (click) = "openPackageInformationDialog()">
    <div class = "name">
        {{ packageDefinition.displayedName }}
    </div>
    <div class = "package-info-container">
        <button class = "package-info">
            <span class = "package-info-text"> Tartalom </span>
        </button>
    </div>
</div>
<div class = "header usable">
    <div class = "title">
        Felhasználható
    </div>
    <div class = "count">
        {{ packageDefinition.redeemableCount }}
    </div>

</div>
<div class = "actions" style="margin-top: 5px;">
    <button 
        class = "app-button hand-out-package-button"
        [disabled] = "packageDefinition.redeemableCount === 0"
        (click) = "handleHandOutPackageButtonClick()"
    >
        <span class = "material-icons">
            card_giftcard
        </span>
        Csomag kiosztása
    </button>
</div>
<div style="margin-top:5px;">
    <button
        class = "app-button purchase-button"
        (click) = "handleOpenPurchasePackageDialogClick()"
        [disabled] = "!isPurchaseAvailable"
    >
        <span class = "material-icons">
            shopping_cart
        </span>
        Vásárlás    
    </button>
</div>
