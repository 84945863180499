@if (studentInfo) {
  <div class = "header">
    <div
      class = "profile-image-container"
      [ngStyle] = "{ 'background-image': 'url(' + studentInfo.profileImageUrl + ')' }"
      >
      <!-- Profil picture goes here -->
    </div>
    <div class = "student-name">
      @if (studentInfo.fullName) {
        <span>
          {{ studentInfo.fullName }}
        </span>
      }
      @if (!studentInfo.fullName) {
        <span class = "no-data">
          Nincs megjeleníthető név
        </span>
      }
    </div>
  </div>
  <div class = "student-datas">
    <div class = "student-data">
      <span class = "data-name">
        Email:
      </span>
      <span>
        {{ studentInfo.email }}
        @if (!studentInfo.email) {
          <span class = "no-data">
            Nincs megjeleníthető e-mail cím
          </span>
        }
      </span>
    </div>
    <div class = "student-data">
      <span class = "data-name">
        ID:
      </span>
      <span>
        {{ studentInfo.uuid }}
      </span>
    </div>
  </div>
}
@if (!studentInfo) {
  <div
    class = "app-loading-container"
    >
    <mat-spinner></mat-spinner>
  </div>
}
<div class = "close-button-container">
  <div class = "close-button" mat-dialog-close>
    Bezár
  </div>
</div>

