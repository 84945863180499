<div class = "title">
    Információk vásárlás előtt
</div>
<mat-dialog-content>
    <div class = "info-section">
        <div class = "section-title">
            Adószám megadása
        </div>
        <div>
            A "Megértettem" gombra kattintva átirányítunk a Stripe online fizetési szolgáltató titkosított oldalára. Ezen az oldalon a közösségi
            adószámod add meg, ami HU-val kezdődik!
            <div class = "example">
                Példa: HU23906062
            </div>
        </div>
    </div>
    <div class = "checkbox">
        <mat-checkbox
            [(ngModel)] = "notShowPurchaseInfoDialogAgain"
            (change) = "notShowPurchaseInfoDialogAgainChangeEvent($event)"
        >   
            Ne jelenjen meg többször az üzenet
        </mat-checkbox>
    </div>
</mat-dialog-content>
<div class = "button-container">
    <div class = "understand-button" mat-dialog-close>
        Megértettem
    </div>
</div>

