<div class="app-title">
  Kiosztott csomagok
</div>

@if (!isContentLoading) {
  @if (handedOutPackages.length !== 0) {
    <table class="redeemed-packages-table">
      <tr class="header">
        <td> Dátum </td>
        <td> Csomag neve </td>
        <td> Tanuló </td>
        <td> Státusz </td>
      </tr>
      @for (handedOutPackage of handedOutPackages; track handedOutPackage) {
        <tr class="data-row">
          <!-- kiosztás időpontja -->
          <td> {{ handedOutPackage.handOutTimestamp | date: "YYYY-MM-dd HH:mm" }} </td>
          <!-- csomag neve -->
          <td> {{ handedOutPackage.displayedPackageName }} </td>
          <!-- tanuló mail címe -->
          <td>
            <div class="stundent-email-container">
              {{ handedOutPackage.studentEmail }}
            </div>
          </td>
          <!-- gomb + átvételre vár / beváltva text-->
          <td (click)="handedOutPackage.isPending ? handleModifyHandoutButtonClick(handedOutPackage) : openStudentInfoDialog(handedOutPackage)">
            <div style="display:flex;align-items: center; column-gap: 10px;cursor:pointer;">
              @if (handedOutPackage.isPending) {
                <div class="item-action modify-handout">
                  <span class="material-icons"> edit </span>
                </div>
              }
              @if (!handedOutPackage.isPending) {
                <div class="item-action open-student-info">
                  <span class="material-icons"> person_search </span>
                </div>
              }
              @if (handedOutPackage.isPending) {
                <div>
                  <span>Átvételre vár</span>
                </div>
              }
              @if (!handedOutPackage.isPending) {
                <div class="redemption-timestamp">
                  <div> Beváltva </div>
                  @if (handedOutPackage.redemptionTimestamp) {
                    <div>
                      ({{ handedOutPackage.redemptionTimestamp | date: "YYYY-MM-dd HH:mm"}})
                    </div>
                  }
                </div>
              }
            </div>
          </td>
        </tr>
      }
    </table>
  }
  @if (handedOutPackages.length !== 0) {
    <div class="package-cards">
      @for (handedOutPackage of handedOutPackages; track handedOutPackage) {
        <div class="package-card">
          <div class="card-data">
            <div class="card-data-title">
              Kiosztás dátuma:
            </div>
            <div class="card-data-value">
              {{ handedOutPackage.handOutTimestamp | date: "YYYY-MM-dd HH:mm" }}
            </div>
          </div>
          <div class="card-data">
            <div class="card-data-title">
              Csomag neve:
            </div>
            <div class="card-data-value">
              {{ handedOutPackage.displayedPackageName }}
            </div>
          </div>
          <div class="card-data">
            <div class="card-data-title">
              Tanuló:
            </div>
            <div class="card-data-value">
              <div class="stundent-email-container">
                {{ handedOutPackage.studentEmail }}
                @if (handedOutPackage.isPending) {
                  <div class="item-action modify-handout"
                    (click)="handleModifyHandoutButtonClick(handedOutPackage)">
                    <span class="material-icons"> edit </span>
                  </div>
                }
                @if (!handedOutPackage.isPending) {
                  <div class="item-action open-student-info"
                    (click)="openStudentInfoDialog(handedOutPackage)">
                    <span class="material-icons"> person_search </span>
                  </div>
                }
              </div>
            </div>
          </div>
          <div class="card-data">
            <div class="card-data-title">
              Státusz:
            </div>
            <div class="card-data-value">
              @if (handedOutPackage.isPending) {
                <div>
                  Átvételre vár
                </div>
              }
              @if (!handedOutPackage.isPending) {
                <div>
                  <span> Beváltva </span>
                  @if (handedOutPackage.redemptionTimestamp) {
                    <span>
                      ({{ handedOutPackage.redemptionTimestamp | date: "YYYY-MM-dd HH:mm"}})
                    </span>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      }
    </div>
  }
  @if (handedOutPackages.length === 0) {
    <div class="app-no-data">
      Itt fogod látni a tanulóknak kiküldött csomagjaid.
    </div>
  }
}

@if (isContentLoading) {
  <div class="app-loading-container">
    <mat-spinner></mat-spinner>
  </div>
}