import { Injectable } from '@angular/core';
import { UserPermissionString } from '../models/permissions';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { SessionService } from './common/session.service';

@Injectable({
  providedIn: 'root'
})
export class SelfPermissionsService {
  private readonly vendorSelfPermissionApiUrlFragment:string = "/api/vendor/permission/self_permission";

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService,
    private sessionService:SessionService
  ) { }

  public async updateSelfPermissions():Promise<void> {
    try {
      const response:{ permissions:Array<UserPermissionString> } = await this.backendService.callApi(this.vendorSelfPermissionApiUrlFragment, "GET");
      this.sessionService.updatePermissions(response.permissions);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a saját jogosultságok lekérdezése közben.");
    }
  }
}
