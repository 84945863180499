export type UserPermissionString = "PAYMENT_REQUEST";

export class UserPermissions {
    public static readonly paymentRequest:UserPermissionString = "PAYMENT_REQUEST";
}

export function isRequiredPermissionsmet(requiredPermissions:ReadonlyArray<UserPermissionString>, userPermissions:ReadonlyArray<UserPermissionString>):boolean {
    return requiredPermissions.length === 0 || !requiredPermissions.some(
        (requiredPermission:UserPermissionString) => {
            return !userPermissions.includes(requiredPermission);
        }
    ); 
}