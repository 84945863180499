import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { SessionService } from '../session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuardService  {

  constructor(
    private router:Router,
    private sessionService:SessionService
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state:RouterStateSnapshot):boolean {
    if(!state.url.includes("/login") && !this.sessionService.isThereLocalSession()) {
      console.warn("Unauthorized access prevented.");
      this.router.navigate(["login"]);
    }

    if(state.url.includes("/login") && this.sessionService.isThereLocalSession()) {
      this.router.navigate([ AuthenticationService.initialLoggedInRoute ]);
    }

    return true;
  }
}
