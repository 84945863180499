import { Injectable } from '@angular/core';
import { Stripe } from 'stripe';
import { copyToArray } from '../functions/misc';
import { PackagePurchase } from '../models/package-package';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { DataCacherService } from './data-cacher-service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService implements DataCacherService {
  private readonly listPaymentsApiUrlFragment:string = "/api/vendor/payments";
  private readonly syncPaymentsApiUrlFragment:string = "/api/vendor/payments/sync";
  private readonly createCheckoutSessionApiUrlFragment:string = "/api/vendor/payments/create_checkout_session";

  private packagePurchases:Array<PackagePurchase>;

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) {
    this.packagePurchases = new Array<PackagePurchase>();
  }

  public clearCachedData():void {
    this.packagePurchases.length = 0;
  }

  public async createCheckoutSession(packageDefinitionUuid:string, offerUuid:string,  quantity:number):Promise<void> {
    const httpBody:Object = {
      data: {
        packageDefinitionUuid: packageDefinitionUuid,
        offerUuid: offerUuid,
        quantity: quantity
      }
    }

    try {
      const response:{ checkoutUrl:string } = await this.backendService.callApi(this.createCheckoutSessionApiUrlFragment, "POST", httpBody);
      window.location.href = response.checkoutUrl;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba történt a fizetés közben.")
    }
  }

  public async getPackagePurchases():Promise<ReadonlyArray<PackagePurchase>> {
    try {
      const response:{ invoices:Array<PackagePurchase> } = await this.backendService.callApi(this.listPaymentsApiUrlFragment, "GET");
      this.packagePurchases.length = 0;
      copyToArray(this.packagePurchases, response.invoices);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a vásárlások lekérdezse közben");
    }

    return this.packagePurchases;
  }

  public async syncPayments():Promise<void> {
    try {
      await this.backendService.callApi(this.syncPaymentsApiUrlFragment, "POST");
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a szinkronizálás közben.");
    }
  }
}
