import { Injectable, Injector, ProviderToken } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from './backend.service'
import { SessionService, UserSession } from './session.service';
import { sha256 } from 'js-sha256';
import { PackagesService } from '../packages.service';
import { PaymentService } from '../payment.service';
import { DataCacherService } from '../data-cacher-service';
import { PaymentRequestService } from '../payment-request.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public static readonly initialLoggedInRoute:string = "dashboard/my-packages";

  constructor(
    private sessionService:SessionService,
    private backendService:BackendService,
    private router:Router,
    private injector:Injector
  ) { }

  public async login(email:string, password:string):Promise<LoginResult> {
    let loginResult:LoginResult;
    email += !email.includes('@') ? "@gmail.com" : "";
    const httpBody:Object = { credentials: { email: email, password: sha256(password) } };

    try {
      const response: LoginResponse = await this.backendService.callApi("/api/vendor/auth/get_token", "POST", httpBody);
      switch(response.result) {
        case "ACCESS_GRANTED":
          loginResult = LoginResult.Success;
          this.sessionService.createLocalSession(response.data);
          await this.router.navigate([ AuthenticationService.initialLoggedInRoute ]);
          break;
        case "ACCESS_DENIED":
          loginResult = LoginResult.WrongCredentials;
          break;
        default:
          loginResult = LoginResult.ServerError;
      }
    } catch(error:any) {
      if(error?.error?.error) {
        loginResult = LoginResult.ServerError;
      } else {
        loginResult = LoginResult.ServerConnectionError;
      }
    }

    return loginResult;
  }

  public async logout():Promise<void> {
    this.sessionService.deleteLocalSession();
    this.clearCachedDatas();
    await this.router.navigate(["login"]);
  }

  public clearCachedDatas():void {
    const dataCacherServices:Array<ProviderToken<DataCacherService>> = [
      PackagesService,
      PaymentService,
      PaymentRequestService
    ];
    
    dataCacherServices.forEach(
      (serviceClass:ProviderToken<DataCacherService>) => {
        const service:DataCacherService|null = this.injector.get(serviceClass, null);
        service?.clearCachedData?.();
      }
    );
  }
}

export enum LoginResult {
  Success, WrongCredentials, ServerError, ServerConnectionError
}

type LoginResponse = {
  result: "ACCESS_GRANTED" | "ACCESS_DENIED",
  data: UserSession 
}