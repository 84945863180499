import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DescriptionLinesItem } from 'src/app/models/package-definition';

export type PackageDetailedDescriptionDialogInput = {
  descriptionLines: Array<DescriptionLinesItem>;
  packageDefinitionDisplayedName: string;
}

@Component({
  selector: 'app-package-detailed-description-dialog',
  templateUrl: './package-detailed-description-dialog.component.html',
  styleUrls: ['./package-detailed-description-dialog.component.scss']
})
export class PackageDetailedDescriptionDialogComponent {

  constructor(
    @Inject (MAT_DIALOG_DATA) public data: PackageDetailedDescriptionDialogInput
  ) { }

 
  public static open(
    dialogService: MatDialog,
    data: PackageDetailedDescriptionDialogInput
  ): MatDialogRef<PackageDetailedDescriptionDialogComponent, void> {
    return dialogService.open<
      PackageDetailedDescriptionDialogComponent,
      PackageDetailedDescriptionDialogInput,
      void
    >(
      PackageDetailedDescriptionDialogComponent,
      {
        data: data,
        scrollStrategy: new NoopScrollStrategy(),
        hasBackdrop: true,
        backdropClass: 'invisible-backdrop',
        maxWidth: "min(90vw, 600px)"
      }
    );
  }


}
