import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value:number, roundingStrategy:(value:number) => number = Math.round):string {
    const valueAsString:string = roundingStrategy(value).toString();
    let result:string = valueAsString.slice(0, valueAsString.length % 3);
    for(let start = result.length; start < valueAsString.length; start +=3) {
      result += " " + valueAsString.slice(start, start + 3);
    }
    return result;
  }

}
