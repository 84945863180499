import { Component, OnInit } from '@angular/core';
import { PackagePurchase } from 'src/app/models/package-package';
import { PaymentService } from 'src/app/services/payment.service';
import Stripe from 'stripe';

@Component({
  selector: 'app-my-purchases',
  templateUrl: './my-purchases.component.html',
  styleUrls: ['./my-purchases.component.scss']
})
export class MyPurchasesComponent implements OnInit {
  isContentLoading:boolean = false;

  displayedPayments:Array<DisplayedPayment> = [];

  constructor(
    private paymentService:PaymentService
  ) { }

  public async ngOnInit():Promise<void> {
    this.isContentLoading = true;

    try {
      await this.loadData();
    } catch(error:any) {}

    this.isContentLoading = false;
  }

  private async loadData():Promise<void> {
    const packagePurchases:ReadonlyArray<PackagePurchase> = await this.paymentService.getPackagePurchases();

      this.displayedPayments = packagePurchases.map(
        (packagePurchase:PackagePurchase) => {
          return {
            created: packagePurchase.purchaseTimestamp,
            itemName: packagePurchase.purchasedPackageName ?? "Ismeretlen elem",
            quantity: packagePurchase.quantity ?? "Ismeretlen",
            price: packagePurchase.totalGrossValue,
            currency: "HUF",
            invoiceUrl: packagePurchase.invoiceUrl
          }
        }
      );

      this.displayedPayments.sort(
        (payment1:DisplayedPayment, payment2:DisplayedPayment) => {
          return payment2.created - payment1.created;
        }
      );
  }

  public async syncPackagePurchases():Promise<void> {
    this.isContentLoading = true;

    try {
      await this.paymentService.syncPayments();
      await this.loadData();
    } catch(error:any) {}

    this.isContentLoading = false;
  }
}

type DisplayedPayment = {
  created:number;
  itemName:string;
  quantity:number|string;
  price:number;
  currency:string;
  invoiceUrl:string|null;
};
