// ########## Modules and services ##########
// Built-in components and modules
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
// Angular material modules
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
// 3rd party modules, services, etc.
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import player, { LottiePlayer } from 'lottie-web';

// ########## Components ##########
// Built-in components
import { AppComponent } from './app.component';
// Custom components
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/dashboard/header/header.component';
import { MyPackagesComponent } from './components/dashboard/my-packages/my-packages.component';
import { HandedOutPackagesComponent } from './components/dashboard/handed-out-packages/handed-out-packages.component';
import { PackageDefinitionCardComponent } from './components/dashboard/my-packages/package-definition-card/package-definition-card.component';
import { HandOutPackageDialogComponent } from './components/dashboard/my-packages/hand-out-package-dialog/hand-out-package-dialog.component';
import { PurchasePackagesDialogComponent } from './components/dashboard/my-packages/purchase-packages-dialog/purchase-packages-dialog.component';
import { PurchaseResultDialogComponent } from './components/dashboard/my-packages/purchase-result-dialog/purchase-result-dialog.component';
import { MyPurchasesComponent } from './components/dashboard/my-purchases/my-purchases.component';

import { StudentInfoDialogComponent } from './components/dashboard/common/student-info-dialog/student-info-dialog.component';
import { LottieAnimationComponent } from './components/common/lottie-animation/lottie-animation.component';
import { PricePipe } from './pipes/price.pipe';
import { PurchaseInformationsDialogComponent } from './components/dashboard/my-packages/purchase-packages-dialog/purchase-informations-dialog/purchase-informations-dialog.component';
import { NetToGrossValuePipe } from './pipes/net-to-gross-value.pipe';
import { GetGiftWithColleagueDialogComponent } from './components/dashboard/my-packages/get-gift-with-colleague-dialog/get-gift-with-colleague-dialog.component';
import { CustomMatDialogService } from './services/custom-mat-dialog.service';
import { ClipboardModule } from '@angular/cdk/clipboard';
// Note: we need a separate function as it's required by the AOT compiler.
export function playerFactory(): LottiePlayer {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    MyPackagesComponent,
    HandedOutPackagesComponent,
    PackageDefinitionCardComponent,
    HandOutPackageDialogComponent,
    PurchasePackagesDialogComponent,
    PurchaseResultDialogComponent,
    MyPurchasesComponent,
    StudentInfoDialogComponent,
    LottieAnimationComponent,
    PricePipe,
    PurchaseInformationsDialogComponent,
    NetToGrossValuePipe,
    GetGiftWithColleagueDialogComponent
  ],
  imports: [
    // Built-in components and modules
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    // Cdk modules,
    ClipboardModule,
    // Angular material modules
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    LottieComponent
  ],
  providers: [
    {
      provide: MatDialog,
      useClass: CustomMatDialogService
    },
    // Akiknek pedig kell az open options
    {
      provide: CustomMatDialogService,
      useClass: CustomMatDialogService
    },
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
