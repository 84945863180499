import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RedeemablePackageDefinition } from 'src/app/models/package-definition';
import { HandOutPackageDialogComponent } from '../hand-out-package-dialog/hand-out-package-dialog.component';
import { PurchasePackagesDialogComponent } from '../purchase-packages-dialog/purchase-packages-dialog.component';
import { PackageDetailedDescriptionDialogComponent } from '../../../../modules/shared/components/package-detailed-description-dialog/package-detailed-description-dialog.component';
import { lastValueFrom } from 'rxjs';
import { PackagesService } from 'src/app/services/packages.service';

@Component({
  selector: 'app-package-definition-card',
  templateUrl: './package-definition-card.component.html',
  styleUrls: ['./package-definition-card.component.scss']
})
export class PackageDefinitionCardComponent implements OnInit {
  @Input() packageDefinition!: RedeemablePackageDefinition
  @Input() isPurchaseAvailable: boolean = true;
  @Output() handleOutButtonClick:EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private dialogService:MatDialog,
    private packageService: PackagesService
  ) { }

  public ngOnInit(): void {

  }

  public async handleHandOutPackageButtonClick():Promise<void> {
    if(this.packageDefinition.redeemableCount === 0) {
      return;
    }

    const matDialog = this.dialogService.open(
      HandOutPackageDialogComponent,
      {
        data: {
          packageDefinition: this.packageDefinition
        },
        scrollStrategy: new NoopScrollStrategy(),
        hasBackdrop: true,
        backdropClass: 'invisible-backdrop',
      }
    );

    // spagetti kód, Jenőő, refaktorálni kell az egész oldalt
    const close = await lastValueFrom(matDialog.afterClosed());

    this.handleOutButtonClick.emit();
  }

  public handleOpenPurchasePackageDialogClick():void {
    if(!this.isPurchaseAvailable) {
      return;
    }

    this.dialogService.open(
      PurchasePackagesDialogComponent,
      {
        data: {
          packageDefinition: this.packageDefinition
        },
        scrollStrategy: new NoopScrollStrategy(),
        hasBackdrop: true,
        backdropClass: 'invisible-backdrop',
        height: "calc(90vh - 50px)"
      }
    );
  }

  public openPackageInformationDialog():void {
    PackageDetailedDescriptionDialogComponent.open(
      this.dialogService,
      {
        packageDefinitionDisplayedName: this.packageDefinition.displayedName,
        descriptionLines: this.packageDefinition.descriptionLines
      }
    );
  }

}
