import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-purchase-informations-dialog',
  templateUrl: './purchase-informations-dialog.component.html',
  styleUrls: ['./purchase-informations-dialog.component.scss']
})
export class PurchaseInformationsDialogComponent implements OnInit {

  notShowPurchaseInfoDialogAgain!:boolean;

  constructor(
    private settingsService:SettingsService
  ) { }

  ngOnInit(): void {
    this.notShowPurchaseInfoDialogAgain = this.settingsService.getSettingsValue("showPurchaseInfoDialogAgain", false);
  }

  public notShowPurchaseInfoDialogAgainChangeEvent(event:MatCheckboxChange):void {
    this.settingsService.setSettingsValue("showPurchaseInfoDialogAgain", !event.checked);
  }
  

}
