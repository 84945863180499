<div class = "dialog-title">
    Csomag {{ data.packageUuid ? 'újra' : '' }}kiosztása
</div>
<div class = "package-description">
    <span style = "font-weight: 500;">
        Kiosztani kívánt csomag:
    </span>
    <span>
        {{ data.packageDefinition.displayedName }}
    </span>
</div>
<div>
    <mat-form-field class = "student-email-form-field">
        <mat-label> Tanulói e-mail cím </mat-label>
        <input matInput [formControl] = "studentEmailFC">
    </mat-form-field>
</div>
<div class = "hand-out-button-container">
    <button
        class = "app-button hand-out-button"
        [disabled] = "studentEmailFC.invalid || isRequestLoading"
        [class.button-loading] = "isRequestLoading"
        (click) = "handleHandOutButtonClick()"
    >
        Kiosztás
    </button>
</div>