import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MyPackagesComponent } from './components/dashboard/my-packages/my-packages.component';
import { MyPurchasesComponent } from './components/dashboard/my-purchases/my-purchases.component';
import { HandedOutPackagesComponent } from './components/dashboard/handed-out-packages/handed-out-packages.component';
import { LoginComponent } from './components/login/login.component';
import { AuthorizationGuardService } from './services/common/guards/authorization-guard.service';
import { RoleGuardService } from './services/common/guards/role-guard.service';

const routes: Routes = [
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [ AuthorizationGuardService ],
    children: [
      { path: "my-packages", component: MyPackagesComponent, canActivate: [ RoleGuardService ] },
      { path: "redeemed-packages", component: HandedOutPackagesComponent, canActivate: [ RoleGuardService ] },
      { path: "my-purchases", component: MyPurchasesComponent, canActivate: [ RoleGuardService ] },
      {
        path: "my-vendor-selling-page",
        canActivate: [ RoleGuardService ],
        loadChildren: () => {
          return import('./modules/pages/my-vendor-selling-page/my-vendor-selling-page.module').then(
            m => m.MyVendorSellingPageModule
          )
        }
      },
    ]
  }, 
  { path: "login", component: LoginComponent, canActivate: [ AuthorizationGuardService ] },
  { path: "**", pathMatch: "full", redirectTo: "login" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
