@if (isRequestLoading) {
  <div class = "loading-container">
    <div class = "loading">
      <mat-spinner></mat-spinner>
      <div style = "margin-top: 20px; text-align: center;">
        Jogosultságok lekérdezése
      </div>
    </div>
  </div>
}
@if (!isRequestLoading) {
  <app-header [parentElementRef] = "elementRef"></app-header>
  <router-outlet></router-outlet>
}

