import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { ComponentReadyState, ComponentState } from 'src/app/models/common/component-state';
import { GetGiftWithColleagueService } from '../services/get-gift-with-colleague.service';
import { CustomMatDialogConfig } from 'src/app/services/custom-mat-dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { awaitFor } from 'src/app/functions/misc';


@Component({
  selector: 'app-get-gift-with-colleague-dialog',
  templateUrl: './get-gift-with-colleague-dialog.component.html',
  styleUrls: ['./get-gift-with-colleague-dialog.component.scss']
})
export class GetGiftWithColleagueDialogComponent implements OnInit {

  public componentStateSubject: BehaviorSubject<ComponentState<string>>;

  constructor(
    private getGiftWithColleagueService: GetGiftWithColleagueService,
    private snackBarService: MatSnackBar
  ) {
    this.componentStateSubject = new BehaviorSubject<ComponentState<string>>({ stateName: "loading" });
  }

  ngOnInit(): void {
    this.updateLinkForColleague();
  }

  /**
   * Updates the generated link for the vendor.
   */
  private async updateLinkForColleague(): Promise<void> {
    this.componentStateSubject.next({ stateName: "loading" });


    const maxTries: number = 3;
    let tryCount: number = 1;
    // Try to get the generated link
    for(tryCount = 1; tryCount <= maxTries; ++tryCount) {
      try {
        const link: string = await this.getGiftWithColleagueService.getUniqueLinkForInvitation();
        this.componentStateSubject.next({ stateName: "ready", data: link });
        break;
      } catch(error: any) {
        // Nothing to do
      }
    }

    // If the try count is bigger than the set max tries, set the component to error state
    if(tryCount > maxTries) {
      this.componentStateSubject.next({ stateName: "error" });
    }
  }

  public getLinkFromComponentState(componentState: ComponentState): string {
    return (componentState as ComponentReadyState<string>).data!;
  }


  public onCopyLinkButtonClick(): void {
    this.snackBarService.open("✅ Link kimásolva", "", { duration: 2000, panelClass: "centered-snackbar-message" });
  }
  
  public static open(
    dialogService: MatDialog,
    viewContainerRef: ViewContainerRef,
    customMatDialogConfig?: CustomMatDialogConfig<any>
  ): MatDialogRef<GetGiftWithColleagueDialogComponent, void> {
    return dialogService.open<
      GetGiftWithColleagueDialogComponent, void, void
    >(
      GetGiftWithColleagueDialogComponent,
      {
        disableClose: true,
        width: "90vw",
        maxWidth: "700px",
        autoFocus:false,
        restoreFocus: false,
        maxHeight: "calc(100vh - 100px)",
        scrollStrategy: new NoopScrollStrategy(),
        hasBackdrop: true,
        backdropClass: 'invisible-backdrop',
        panelClass: ["get-gift-with-collegue-dialog-panel"],
        viewContainerRef: viewContainerRef,
        ...(customMatDialogConfig ?? {})
      }
    )
  };
}

