import { Injectable } from "@angular/core";
import { awaitFor } from "src/app/functions/misc";
import { BackendService } from "src/app/services/common/backend.service";
import { HttpErrorHandlerService } from "src/app/services/common/http-error-handler.service";

@Injectable()
export class GetGiftWithColleagueService {
  private readonly apiPath: string = "/api/vendor/invitation/my_invitation_link";

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) {}

  public async getUniqueLinkForInvitation(): Promise<string> {
    let link: string = "";
    
    try {
      const response: { invitationLink: string } = await this.backendService.callApi(this.apiPath, "GET");
      link = response.invitationLink;
    } catch(error: any) {
      throw error;
    }

    return link;
  }
}

class UnqieMockupLinkProvider {
  public async getUniqueLink():Promise<{ uniqueLinkForInvitation: string }> {
    await awaitFor(2000);
    const symbols: Array<string> = [ "A", "B", "C", "D", "1", "2", "3", "4" ];
    const length: number = 6;
    
    let result: string = "mrkresz.hu/partnerek/";
    for(let index: number = 0; index < length; ++index) {
      result += symbols[Math.floor(Math.random() * symbols.length)];
    }

    return { uniqueLinkForInvitation: result };
  }
}