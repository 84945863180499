import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, Event, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { MenuItem, menuItems } from 'src/app/models/menu-items';
import { isRequiredPermissionsmet, UserPermissionString } from 'src/app/models/permissions';
import { AuthenticationService } from 'src/app/services/common/authentication.service';
import { SessionService } from 'src/app/services/common/session.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() parentElementRef!:ElementRef<HTMLElement>;

  menuItems!:ReadonlyArray<MenuItem>;

  isMenuOpen:boolean = false;
  selectedMenuIndex:number = 0;
  routeChangeSubscription!:Subscription;
  resizeObserver:ResizeObserver;

  displayedUsername!:string;
  displayedUserType!:string;

  constructor(
    private elementRef:ElementRef<HTMLElement>,
    private authenticationService:AuthenticationService,
    private routerService:Router,
    private sessionService:SessionService,
    private changeDetectorRef:ChangeDetectorRef
  ) {
    this.resizeObserver = new ResizeObserver(
      () => {
        if(elementRef.nativeElement.clientWidth >= 1000 && this.isMenuOpen) {
          this.toogleMenu();
        }
    })
  }

  ngOnInit(): void {
    const userPermissions:ReadonlyArray<UserPermissionString> = this.sessionService.getUserPermissions() ?? [];
    this.menuItems = menuItems.filter(menuItem => isRequiredPermissionsmet(menuItem.requiredPermissions, userPermissions));

    this.displayedUsername = this.sessionService.getUsername() ?? "Ismeretlen felhasználó";

    switch(this.sessionService.getUserType()) {
      case "instructor":
        this.displayedUserType = "Oktató";
        break;
      case "school":
        this.displayedUserType = "Autósiskola";
        break;
      case "other":
        this.displayedUserType = "Egyéb felhasználó";
        break;
      default:
        this.displayedUserType = "Ismeretlen felhasználó típus";
    }

    this.routeChangeSubscription = this.routerService.events.subscribe(
      (routerEvent:Event) => {
        if(this.isMenuOpen) {
          this.toogleMenu();
        }
        this.setSelectedMenuIndex(routerEvent);
      }
    );

    this.resizeObserver.observe(this.elementRef.nativeElement);

    this.setSelectedMenuIndex();
  }

  public ngOnDestroy():void {
    this.routeChangeSubscription?.unsubscribe();
    this.resizeObserver.disconnect();
  }

  private setSelectedMenuIndex(routerEvent?:Event) {
    if(routerEvent) {
      if(routerEvent instanceof NavigationStart) {
        const selectedMenuIndex:number = this.menuItems.findIndex(menuItem => routerEvent.url.includes(menuItem.route));
        this.selectedMenuIndex = selectedMenuIndex >= 0 ? selectedMenuIndex : 0;
      }
    } else {
      const selectedMenuIndex:number = this.menuItems.findIndex(menuItem => this.routerService.url.includes(menuItem.route));
      this.selectedMenuIndex = selectedMenuIndex >= 0 ? selectedMenuIndex : 0;
    }
  }

  public async logout():Promise<void> {
    await this.authenticationService.logout();
  }

  public toogleMenu():void {
    this.isMenuOpen = !this.isMenuOpen;
    
    if(this.isMenuOpen) {
      this.parentElementRef.nativeElement.style.overflow = "hidden";
      this.parentElementRef.nativeElement.style.height = "100vh";
      this.elementRef.nativeElement.classList.add("is-expanded");
    } else {
      this.elementRef.nativeElement.classList.remove("is-expanded");
      this.parentElementRef.nativeElement.style.removeProperty("overflow");
      this.parentElementRef.nativeElement.style.removeProperty("height");
    }

    this.changeDetectorRef.detectChanges();
  }

}