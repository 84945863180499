<div class = "section-header">
  <div class = "app-title">
    Vásárlásaim
  </div>

  @if (!isContentLoading) {
    <div
      class = "app-button sync"
      (click) = "syncPackagePurchases()"
      >
      <span class = "material-icons">
        cached
      </span>
      Szinkronizálás
    </div>
  }
</div>

@if (!isContentLoading) {
  @if (displayedPayments.length !== 0) {
    <table class = "payment-table">
      <tr class = "header">
        <td> Dátum </td>
        <td> Csomag neve </td>
        <td> Mennyiség </td>
        <td> Ár (bruttó) </td>
        <td></td>
      </tr>
      @for (displayedPayment of displayedPayments; track displayedPayment) {
        <tr class = "data-row">
          <td> {{ displayedPayment.created | date: "YYYY-MM-dd HH:mm"  }} </td>
          <td> {{ displayedPayment.itemName }} </td>
          <td> {{ displayedPayment.quantity }} </td>
          <td> {{ displayedPayment.price }} {{ displayedPayment.currency }} </td>
          <td style = "text-align: center;">
            @if (displayedPayment.invoiceUrl) {
              <a [href] = "displayedPayment.invoiceUrl" target = "_blank">
                <div class = "dowload-invoice-button">
                  Számla letöltése
                </div>
              </a>
            }
            @if (!displayedPayment.invoiceUrl) {
              <div class = "no-invoice-available">
                Számla nem elérhető
              </div>
            }
          </td>
        </tr>
      }
    </table>
  }
  @if (displayedPayments.length !== 0) {
    <div class = "payment-cards">
      @for (displayedPayment of displayedPayments; track displayedPayment) {
        <div class = "payment-card">
          <div class = "card-data">
            <div class = "card-data-title">
              Dátum:
            </div>
            <div class = "card-data-value">
              {{ displayedPayment.created | date: "YYYY-MM-dd HH:mm"  }}
            </div>
          </div>
          <div class = "card-data">
            <div class = "card-data-title">
              Csomag neve:
            </div>
            <div class = "card-data-value">
              {{ displayedPayment.itemName }}
            </div>
          </div>
          <div class = "card-data">
            <div class = "card-data-title">
              Mennyiség:
            </div>
            <div class = "card-data-value">
              {{ displayedPayment.quantity }}
            </div>
          </div>
          <div class = "card-data">
            <div class = "card-data-title">
              Ár (bruttó):
            </div>
            <div class = "card-data-value">
              {{ displayedPayment.price }} {{ displayedPayment.currency }}
            </div>
          </div>
          @if (displayedPayment.invoiceUrl) {
            <a [href] = "displayedPayment.invoiceUrl" target = "_blank">
              <div class = "dowload-invoice-button">
                Számla letöltése
              </div>
            </a>
          }
          @if (!displayedPayment.invoiceUrl) {
            <div class = "no-invoice-available">
              Számla nem elérhető
            </div>
          }
        </div>
      }
    </div>
  }
  @if (displayedPayments.length === 0) {
    <div class = "app-no-data">
      Jelenleg még nincs egyetlen vásárlásod sem!
    </div>
  }
}

@if (isContentLoading) {
  <div class = "app-loading-container">
    <mat-spinner></mat-spinner>
  </div>
}