import { UserPermissionString, UserPermissions } from "./permissions";

export type MenuItem = {
    displayedName:string;
    route:string;
    requiredPermissions:ReadonlyArray<UserPermissionString>
};

export const menuItems:ReadonlyArray<MenuItem> = [
    { displayedName: "Csomag vásárlás", route: "my-packages", requiredPermissions: [] },
    { displayedName: "Kiosztott csomagok", route: "redeemed-packages", requiredPermissions: [] },
    { displayedName: "Vásárlásaim", route: "my-purchases", requiredPermissions: [] },
    { displayedName: "Viszonteladói oldalam", route: "my-vendor-selling-page", requiredPermissions: [] }
];