import { Injectable } from '@angular/core';
import { getFullName } from '../functions/misc';
import { StudentInfo } from '../models/student-info';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  private readonly studentInfoApiUrlFragment:string = "/api/vendor/students";

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) { }

  public async getStudentInfo(studentUuid:string):Promise<StudentInfo> {
    const apiFragment:string = `${this.studentInfoApiUrlFragment}/${studentUuid}`;
    let studentInfo:StudentInfo;
    
    try {
      const response:{ data: { user:StudentInfo } } = await this.backendService.callApi(apiFragment, "GET");
      studentInfo = response.data.user;
      studentInfo.fullName = getFullName(studentInfo.lastName, studentInfo.firstName);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a tanuló lekérdezése közben!");
    }

    return studentInfo!;
  }

}
