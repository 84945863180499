<!-- Title -->
<div class="title">
  Zsebelj be kollégáddal <span style="white-space: nowrap;">25-25</span> ezer forintot!
</div>

<!-- List -->
<div class="step-list">

  <div class="list-item">
    <span>
      <span style="font-weight: 600;">Mesélj egy kollégádnak</span> a viszonteladóságról, és ha tetszik
      neki, akkor
      <span style="font-weight: 600;">hívd meg</span>, hogy csatlakozhasson hozzánk.
    </span>
  </div>


  <div class="list-item">
    <span>
      Küldd el neki az egyedi linked, és kérd meg, hogy <span style="font-weight: 600;">adja le a
    jelentkezését</span> a linken
    keresztül.
  </span>
</div>



<div class="list-item">
  <span>
    Amint elfogadjuk a jelentkezését jóváírunk számodra és neki is
    <span style="font-weight: 600;">25.000 Ft (piaci áron 50.000 Ft) értékben Mr. Kresz csomagot.</span>
  </span>

</div>


<div class="list-item link-container">
  <div style="font-weight: 600;">
    Az egyedi linked:
  </div>
  <div>
    @switch ((componentStateSubject | async)!.stateName) {
      @case ('loading') {
        <span>
          <mat-spinner class="loading-spinner" diameter="14" strokeWidth="2"></mat-spinner>
          <span style="font-style: italic; color: lightgray">
            Link generálása...
          </span>
        </span>
      }
      @case ('error') {
        <span>
          Az egyedi linked megszerzéséért keresd az ügyfélszolgálatunkat!
        </span>
      }
      @case ('ready') {
        <span class="generated-link">
          {{getLinkFromComponentState((componentStateSubject | async)!)}}
        </span>
      }
    }
  </div>
</div>
</div>

<!-- Animation -->
<div class="animation-container">
  <app-lottie-animation [animationPath]="'assets/lottie/gift.json'" [scale]="2"></app-lottie-animation>
</div>

<!-- Actions -->
<div class="actions">
  <button class="app-button action-button copy-link-button"
    [disabled]="(componentStateSubject | async)!.stateName !== 'ready'" (click)="onCopyLinkButtonClick()"
    [cdkCopyToClipboard]="getLinkFromComponentState((componentStateSubject | async)!)">
    <mat-icon> content_copy </mat-icon>
    Link másolása
  </button>

  <button class="app-button-gray action-button" mat-dialog-close>
    Rendben
  </button>
</div>