import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { awaitFor } from 'src/app/functions/misc';
import { StudentInfo } from 'src/app/models/student-info';
import { StudentService } from 'src/app/services/student.service';

@Component({
  selector: 'app-student-info-dialog',
  templateUrl: './student-info-dialog.component.html',
  styleUrls: ['./student-info-dialog.component.scss']
})
export class StudentInfoDialogComponent implements OnInit {

  studentInfo:StudentInfo|null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{ studentUuid:string },
    private dialogRef:MatDialogRef<StudentInfoDialogComponent>,
    private studentService:StudentService
  ) { }

  public async ngOnInit():Promise<void> {
    try {
      this.studentInfo = await this.studentService.getStudentInfo(this.data.studentUuid);
      if(!this.studentInfo.profileImageUrl) {
        this.studentInfo.profileImageUrl = "assets/images/defaultprofile.webp";
      }
    } catch(error:any) {
      this.dialogRef.close();
    }
  }

}
