import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RedeemablePackageDefinition  } from 'src/app/models/package-definition';
import { PackagesService } from 'src/app/services/packages.service';
import { PaymentService } from 'src/app/services/payment.service';
import { GetGiftWithColleagueDialogComponent } from './get-gift-with-colleague-dialog/get-gift-with-colleague-dialog.component';
import { PurchaseResultDialogComponent } from './purchase-result-dialog/purchase-result-dialog.component';
import { GetGiftWithColleagueService } from './services/get-gift-with-colleague.service';

@Component({
  selector: 'app-my-packages',
  templateUrl: './my-packages.component.html',
  styleUrls: ['./my-packages.component.scss'],
  providers: [
    GetGiftWithColleagueService
  ]
})
export class MyPackagesComponent implements OnInit {
  isContentLoading:boolean = false;

  theoryPackageDefinitions:Array<RedeemablePackageDefinition> = [];
  practicePackageDefinitions:Array<RedeemablePackageDefinition> = [];
  miscPackageDefinitions: Array<RedeemablePackageDefinition> = [];
  inactivePackageDefinitions:Array<RedeemablePackageDefinition> = [];

  constructor(
    private packageService:PackagesService,
    private paymentService:PaymentService,
    private dialogService:MatDialog,
    private routerService:Router,
    private activatedRoute:ActivatedRoute,
    private viewContainerRef: ViewContainerRef
  ) { }

  public async ngOnInit():Promise<void> {
    this.isContentLoading = true;
    
    await this.checkPurchaseResultIfExists();
    await this.loadPackageDefinitions();

    this.isContentLoading = false;
  }

  protected async loadPackageDefinitions():Promise<void> {
    try {
      // Szuper spagetti Jenő kódja, ez kell, hogy működjön a kód..
      await this.packageService.getRedeemablePackages();
      
      const packageDefinitions:ReadonlyArray<RedeemablePackageDefinition> = (await this.packageService.getPackageDefinitions()).filter(
        (packageDefinition:RedeemablePackageDefinition) => {
          return (packageDefinition.isActive && packageDefinition.offers.some(offer => offer.offerFor === "vendor")) || packageDefinition.redeemableCount > 0
        }
      );

      this.theoryPackageDefinitions.length = 0;
      this.practicePackageDefinitions.length = 0;
      this.inactivePackageDefinitions.length = 0;

      for(const packageDefinition of packageDefinitions) {
        let targetPackageDefinitionsArray:Array<RedeemablePackageDefinition>|null = null;

        const isPackagePurchaseable: boolean = packageDefinition.isActive && packageDefinition.offers.filter(
          offer => offer.offerFor === "vendor"
        ).length > 0

        if(isPackagePurchaseable) {
          if(packageDefinition.groupName === "theory") {
            targetPackageDefinitionsArray = this.theoryPackageDefinitions;
          } else if(packageDefinition.groupName === "practical") {
            targetPackageDefinitionsArray = this.practicePackageDefinitions;
          } else {
            targetPackageDefinitionsArray = this.miscPackageDefinitions;
          }
        } else {
          targetPackageDefinitionsArray = this.inactivePackageDefinitions;
        }
        
        if(targetPackageDefinitionsArray) {
          targetPackageDefinitionsArray.push(packageDefinition);
        }

      }

    } catch(error:any) {}
  }


  public openPurchaseResultDialog(purchaseResult:string):void {
    const dialogRef:MatDialogRef<PurchaseResultDialogComponent> = this.dialogService.open(
      PurchaseResultDialogComponent,
      {
        data: {
          purchaseResult: purchaseResult
        },
        disableClose: true,
        hasBackdrop: true,
        backdropClass: 'invisible-backdrop',
        scrollStrategy: new NoopScrollStrategy()
      }
    );

    const dialogClosedSubscription:Subscription = dialogRef.afterClosed().subscribe(
      async () => {
        dialogClosedSubscription.unsubscribe();
        await this.routerService.navigate([], { relativeTo: this.activatedRoute, queryParams: {} });
      }
    )
  }

  public async syncPayments():Promise<void> {
    this.isContentLoading = true;

    try {
      await this.paymentService.syncPayments();
      await this.loadPackageDefinitions();
    } catch(error:any) {
      console.error(error);
    }

    this.isContentLoading = false;
  }

  private async checkPurchaseResultIfExists():Promise<void> {
    const purchaseResultQueryParam:string|null = this.activatedRoute.snapshot.queryParams.purchase_result ?? null;
    if(["SUCCESS", "FAILED"].includes(purchaseResultQueryParam ?? "")) {
      this.openPurchaseResultDialog(purchaseResultQueryParam!);
      try {
        await this.paymentService.syncPayments();
      } catch(error:any) {}
    } else {
      await this.routerService.navigate([], { relativeTo: this.activatedRoute, queryParams: {} });
    }
  }

  public onGetGiftWithColleagueButtonClick(): void {
    GetGiftWithColleagueDialogComponent.open(this.dialogService, this.viewContainerRef);
  }

}
