import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { menuItems } from 'src/app/models/menu-items';
import { isRequiredPermissionsmet, UserPermissionString } from 'src/app/models/permissions';
import { SessionService } from '../session.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService  {

  constructor(
    private sessionService:SessionService
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state:RouterStateSnapshot):boolean {
    const userPermissions:ReadonlyArray<UserPermissionString> = this.sessionService.getUserPermissions() ?? [];

    const queriesPosition:number = state.url.indexOf("?");
    const urlWithoutQuery:string = state.url.slice(0, queriesPosition === -1 ? state.url.length : queriesPosition);

    const routeFragments:Array<string> = urlWithoutQuery.split("/");
    const dashboardAt:number = routeFragments.findIndex(fragment => fragment === "dashboard");

    if(routeFragments.length < 2 || dashboardAt < 0) {
      return false;
    }

    const requiredPermissions:ReadonlyArray<UserPermissionString>|undefined = menuItems.find(menuItem => menuItem.route === routeFragments[dashboardAt + 1])?.requiredPermissions;
    if(!requiredPermissions) {
      return false;
    }
    
    return isRequiredPermissionsmet(requiredPermissions, userPermissions);
  }
}
