import { taxValue } from "../constants";

export function awaitFor(ms:number):Promise<void> {
    return new Promise<void>(
        (resolve, reject) => {
            setTimeout(
                () => {
                    resolve();
                },
            ms)
        }
    );
}

export function getFullName(...nameFragments:(string|null|undefined)[]):string {
    return nameFragments.filter(nameFragment => nameFragment).join(" ");
}

export function copyToArray<StoredType>(targetArray:Array<StoredType>, copiedArray:Array<StoredType> = []):void {
    (copiedArray ?? []).forEach(
        (element:StoredType) => {
            targetArray.push(element);
        }
    );
}

/**
 * Converts the net value to gross value (uses Math.round to round).
 * To change the global tax value, change the value in the 'constants' file.
 * 
 * @param value the net value
 * @param customTaxValue if provided, a custom tax value can be used
 * 
 * @returns the (rounded) gross value
*/
export function netToGrossValue(value:number, customTaxValue?:number):number {
    return Math.round(value * (1 + (customTaxValue ?? taxValue)));
}

/**
 * Converts the gross value to net value (uses Math.round to round).
 * To change the global tax value, change the value in the 'constants' file.
 * 
 * @param value the gross value
 * @param customTaxValue if provided, a custom tax value can be used
 * 
 * @returns the (rounded) net value
*/
export function grossToNetValue(value:number, customTaxValue?:number):number {
    return Math.round(value / (1 + (customTaxValue ?? taxValue)));
}