import { ComponentType, Overlay, OverlayContainer } from '@angular/cdk/overlay';
import { Inject, Injectable, Injector, Optional, SkipSelf, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DEFAULT_OPTIONS, MAT_DIALOG_SCROLL_STRATEGY } from '@angular/material/dialog';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { Location } from '@angular/common';
import { TidioChatService } from './tidio-chat.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class CustomMatDialogService extends MatDialog {

  constructor(
    overlay: Overlay, injector: Injector,
    private tidioChatService: TidioChatService,
    private deviceDetectorService: DeviceDetectorService,
    @Optional() location: Location,
    @Optional() @Inject(MAT_DIALOG_DEFAULT_OPTIONS) defaultOptions: MatDialogConfig,
    @Inject(MAT_DIALOG_SCROLL_STRATEGY) scrollStrategy: any,
    @Optional() @SkipSelf() parentDialog: MatDialog,
    overlayContainer: OverlayContainer,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) animationMode?: 'NoopAnimations' | 'BrowserAnimations',
   
  ) {
    super(overlay, injector, location, defaultOptions, scrollStrategy, parentDialog, overlayContainer, animationMode);
  }


  shouldHideTidio(){
    return this.deviceDetectorService.isMobile();
  }

  open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: CustomMatDialogConfig<D>
  ): MatDialogRef<T, R> {
    const autoHideTidio: boolean = config?.autoHideTidio != undefined ? config.autoHideTidio : this.shouldHideTidio();

    if(autoHideTidio) this.tidioChatService.hideChat();
  
    const dialogRef: MatDialogRef<T, R> = super.open(componentOrTemplateRef, config);

    dialogRef.afterClosed().subscribe(() => {
      if(autoHideTidio) this.tidioChatService.showChat();
    });
    
    return dialogRef;
  }

}


type CustomDialogConfigs = { autoHideTidio?: boolean };
export type CustomMatDialogConfig<DataType> = MatDialogConfig<DataType> & CustomDialogConfigs;