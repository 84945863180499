import { Injectable } from '@angular/core';
import { copyToArray } from 'src/app/functions/misc';
import { UserPermissionString } from 'src/app/models/permissions';

export type UserSession = {
  uuid:string;
  username:string;
  email:string;
  sessionId:string;
  firstName?:string;
  lastName?:string;
  name?:string;
  type?:string;
  permissions:Array<UserPermissionString>
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public static readonly StoredUserUuidKey:string = "UserUuidKey";
  public static readonly StoredUserNameKey:string = "UserNameKey";
  public static readonly StoredUserEmailKey:string = "UserEmailKey";
  public static readonly StoredUserSessionIdKey:string = "UserSessionIdKey";
  public static readonly StoredUserPermissionsKey:string = "UserPermissionsKey"
  public static readonly StoredUserTypeKey:string = "UserTypeKey";

  private userSession:UserSession|null = null;

  constructor() {
    this.userSession = this.createUserSessionFromSessionStorage();
  }

  public createLocalSession(userSession:UserSession):void {
    localStorage.setItem(SessionService.StoredUserUuidKey, userSession.uuid);
    
    const userName:string = userSession.name ? userSession.name : [ userSession.lastName ?? "", userSession.firstName ?? "" ].filter(nameFragment => nameFragment !== "").join(" ")

    localStorage.setItem(SessionService.StoredUserNameKey, userName);

    localStorage.setItem(SessionService.StoredUserEmailKey, userSession.email);
    localStorage.setItem(SessionService.StoredUserSessionIdKey, userSession.sessionId ?? (userSession as any).sessionID);
    localStorage.setItem(SessionService.StoredUserPermissionsKey, JSON.stringify(userSession.permissions ?? []));
    localStorage.setItem(SessionService.StoredUserTypeKey, userSession.type ?? "unknown");
    
    this.userSession = this.createUserSessionFromSessionStorage();
  }

  public deleteLocalSession():void {
    localStorage.removeItem(SessionService.StoredUserUuidKey);
    localStorage.removeItem(SessionService.StoredUserNameKey);
    localStorage.removeItem(SessionService.StoredUserEmailKey);
    localStorage.removeItem(SessionService.StoredUserSessionIdKey);
    localStorage.removeItem(SessionService.StoredUserPermissionsKey);
    localStorage.removeItem(SessionService.StoredUserTypeKey);

    this.userSession = null;
  }

  public isThereLocalSession():boolean {
    return this.userSession !== null;
  }

  public getUuid():string|null {
    return this.userSession?.uuid ?? null;
  }

  public getUsername():string|null {
    return this.userSession?.username ?? null;
  }

  public getEmail():string|null {
    return this.userSession?.email ?? null;
  }

  public getSessionId():string|null {
    return this.userSession?.sessionId ?? null;
  }

  public getUserType():Readonly<string|null> {
    return this.userSession?.type ?? null;
  }

  public getUserPermissions():ReadonlyArray<UserPermissionString>|null {
    return this.userSession?.permissions ?? null;
  }

  public getUserSession():Readonly<UserSession|null> {
    return this.userSession;
  }

  public updatePermissions(permissions:Array<UserPermissionString>):void {
    if(this.userSession === null) {
      return;
    }

    this.userSession.permissions.length = 0;
    copyToArray(this.userSession.permissions, permissions);

    localStorage.setItem(SessionService.StoredUserPermissionsKey, JSON.stringify(permissions ?? []));
  }

  private createUserSessionFromSessionStorage():UserSession|null {
    if(this.isThereSavedSessionInSessionStorage()) {
      return {
        uuid:         localStorage.getItem(SessionService.StoredUserUuidKey)!,
        username:     localStorage.getItem(SessionService.StoredUserNameKey)!,
        email:        localStorage.getItem(SessionService.StoredUserEmailKey)!,
        sessionId:    localStorage.getItem(SessionService.StoredUserSessionIdKey)!,
        type:         localStorage.getItem(SessionService.StoredUserTypeKey)!,
        permissions:  JSON.parse(
          localStorage.getItem(SessionService.StoredUserPermissionsKey) ?
          localStorage.getItem(SessionService.StoredUserPermissionsKey)! :
          "[]")
      };
    } else {
      return null;
    }
  }

  private isThereSavedSessionInSessionStorage():boolean {
    return !!localStorage.getItem(SessionService.StoredUserUuidKey) &&
           !!localStorage.getItem(SessionService.StoredUserNameKey) &&
           !!localStorage.getItem(SessionService.StoredUserEmailKey) &&
           !!localStorage.getItem(SessionService.StoredUserSessionIdKey) &&
           !!localStorage.getItem(SessionService.StoredUserTypeKey);
  }

}
