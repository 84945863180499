<div class = "animation-container">
  @if (data.purchaseResult === 'SUCCESS') {
    <app-lottie-animation
      [animationPath] = "'assets/lottie/success.json'"
    ></app-lottie-animation>
  }
  @if (data.purchaseResult === 'FAILED') {
    <app-lottie-animation
      [animationPath] = "'assets/lottie/fail.json'"
      [scale] = "2"
    ></app-lottie-animation>
  }
</div>
<div class = "text">
  {{ text }}
</div>
<div class = "close-button-container">
  <div class = "close-button" mat-dialog-close>
    Bezár
  </div>
</div>