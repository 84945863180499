<div class = "offers">
  @if (baseOffer) {
    <div class = "base-offer">
      <div class = "offer-type-title">
        <div class = "offer-type-title-text">
          Alap ajánlat
        </div>
      </div>
      <div
        (click) = "selectOffer(baseOffer.offerUuid)"
        class = "offer"
        [class.selected-offer] = "selectedOfferId === baseOffer.offerUuid"
        >
        <div class = "offer-title">
          {{ data.packageDefinition.displayedName }} <span style = "white-space: nowrap;"> × {{ baseOffer.quantity }} </span>
        </div>
        <div class = "offer-price-container">
          <div  class = "offer-price">
            {{ baseOffer.netPrice | netToGrossValue | price }} Ft
          </div>
        </div>
      </div>
    </div>
  }
  @if (discountedOffers.length > 0) {
    <div class = "discounted-offers">
      <div class = "offer-type-title">
        <div class = "offer-type-title-text">
          Kedvezményes ajánlat(ok)
        </div>
      </div>
      @for (offer of discountedOffers; track offer; let i = $index) {
        <div
          (click) = "selectOffer(offer.offerUuid)"
          class = "offer"
          [class.selected-offer] = "selectedOfferId === offer.offerUuid"
          >
          <div class = "offer-title">
            {{ data.packageDefinition.displayedName }}<span style = "white-space: nowrap;"> × {{ offer.quantity }} </span>
          </div>
          @if (baseOffer) {
            <div class = "offer-discount">
              {{ discounts[i] | price }} Ft megtakarítás csomagonként!
            </div>
          }
          <div class = "offer-price-container">
            <div  class = "offer-price">
              {{ offer.netPrice | netToGrossValue | price }} Ft
            </div>
          </div>
        </div>
      }
    </div>
  }
</div>
<div class="price-and-actions">
  <div class = "total-container">
    <div class = "total">
      Fizetendő összesen: {{ total | price }} Ft
    </div>
    @if (total > maximumAllowedTotal) {
      <div class = "total-error">
        <div>
          Legfeljebb {{ maximumAllowedTotal }} Ft összegben vásárolhatsz egyszerre.
        </div>
        <div>
          Ha többet szeretnél vásárolni, több részletben teheted meg!
        </div>
      </div>
    }
  </div>
  <div class = "actions">
    <div class = "count-main-container">
      <button
        class = "app-button count-action"
        (click) = "decreaseCount()"
        [disabled] = "count < 2"
        >
        -
      </button>
      <div class = "count" [class.disabled] = "selectedOfferId === null">
        {{ count }}
      </div>
      <button
        class = "app-button count-action"
        (click) = "increaseCount()"
        [disabled] = "selectedOfferId === null"
        >
        +
      </button>
    </div>
    <button
      class = "app-button purchase-button"
      [disabled] = "selectedOfferId === null || isRequestLoading || total > maximumAllowedTotal"
      [class.button-loading] = "isRequestLoading"
      (click) = "handleProceedToCheckoutButtonClick()"
      >
      Tovább a fizetéshez
    </button>
  </div>
</div>
