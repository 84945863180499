import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { netToGrossValue } from 'src/app/functions/misc';
import { RedeemablePackageDefinition, RedeemablePackageOffer } from 'src/app/models/package-definition';
import { PaymentService } from 'src/app/services/payment.service';
import { SettingsService } from 'src/app/services/settings.service';
import { PurchaseInformationsDialogComponent } from './purchase-informations-dialog/purchase-informations-dialog.component';

@Component({
  selector: 'app-purchase-packages-dialog',
  templateUrl: './purchase-packages-dialog.component.html',
  styleUrls: ['./purchase-packages-dialog.component.scss']
})
export class PurchasePackagesDialogComponent implements OnInit {

  selectedOfferId:string|null = null;
  count:number = 1;

  isRequestLoading:boolean = false;

  baseOffer:RedeemablePackageOffer|null = null;
  discountedOffers:Array<RedeemablePackageOffer> = [];
  discounts:Array<number> = [];

  total:number = 0;
  readonly maximumAllowedTotal:number = 990000;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{ packageDefinition:RedeemablePackageDefinition },
    private paymentService:PaymentService,
    private dialogService:MatDialog,
    private settingService:SettingsService
  ) { }

  ngOnInit(): void {
    this.baseOffer = this.data.packageDefinition.offers.find(offer => offer.quantity === 1 && offer.offerFor === "vendor") ?? null;
    this.discountedOffers = this.data.packageDefinition.offers.filter(offer => offer.quantity > 1 && offer.offerFor === "vendor");

    this.discountedOffers.sort(
      (offer1:RedeemablePackageOffer, offer2:RedeemablePackageOffer) => {
        return offer2.quantity - offer1.quantity
      }
    );

    if(this.baseOffer) {
      this.discounts = this.discountedOffers.map(
        (offer:RedeemablePackageOffer) => {
          return netToGrossValue(this.baseOffer!.netPrice) - (netToGrossValue(offer.netPrice) / offer.quantity);
        }
      );
    }
  }

  public selectOffer(offerId:string):void {
    this.selectedOfferId = offerId;
    this.recalculateTotal();
  }

  public decreaseCount():void {
    if(this.count > 1) {
      --this.count;
      this.recalculateTotal();
    }
  }

  public increaseCount():void {
    if(this.selectedOfferId) {
      ++this.count;
      this.recalculateTotal();
    }
  }

  public async handleProceedToCheckoutButtonClick():Promise<void> {
    if(this.selectedOfferId === null || this.isRequestLoading) {
      return;
    }

    const shouldShowInformationDialog:boolean = this.settingService.getSettingsValue("showPurchaseInfoDialogAgain", true);
    if(shouldShowInformationDialog) {
      const dialogRef:MatDialogRef<PurchaseInformationsDialogComponent, any> = this.dialogService.open(
        PurchaseInformationsDialogComponent, { disableClose: true }
      );

      const dialogAfterClosedSubscription:Subscription = dialogRef.afterClosed().subscribe(
        async () => {
          dialogAfterClosedSubscription.unsubscribe();
          await this.createCheckoutSession();
        }
      );
    } else {
      await this.createCheckoutSession();
    }
  }

  private async createCheckoutSession():Promise<void> {
    this.isRequestLoading = true;

    try {
      await this.paymentService.createCheckoutSession(this.data.packageDefinition.uuid, this.selectedOfferId!, this.count);
    } catch(error:any) {}

    this.isRequestLoading = false;
  }

  private recalculateTotal():void {
    const offer:RedeemablePackageOffer|null = this.data.packageDefinition.offers.find(offer => offer.offerUuid === this.selectedOfferId) ?? null;
    if(offer) {
      this.total = netToGrossValue(offer.netPrice) * this.count;
    } else {
      this.total = 0;
    }
  }


}

